import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { createI18n } from "vue-i18n";
import en from "./lang/en.json";
import ar from "./lang/ar.json";
import axios from "axios";
import Toaster from "@meforma/vue-toaster";
import useVuelidate from "@vuelidate/core";
import $ from "jquery";
import VueGoogleMaps from "@fawmi/vue-google-maps";
import GoogleAuth from "vue3-google-oauth2";
// import VueAppleLogin from 'vue-apple-login';


// axios.defaults.baseURL = "https://minpizza.smartlife.ws/api/";
axios.defaults.baseURL = "https://api.leferoo.com/api/";
axios.defaults.withCredentials = false;
axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*";
axios.defaults.headers.common["Accept"] = "application/json";
axios.defaults.headers.common["Authorization"] =
  "Bearer " + (localStorage.getItem("customerToken") || "");
axios.defaults.headers.common["Accept-Language"] =
  localStorage.getItem("appLang") || "ar";

// initialization of translation
const messages = {
  en: en,
  ar: ar
};

const DEFAULT_LANG = localStorage.getItem("appLang") || "ar";

const i18n = createI18n({
  // something vue-i18n options here ...
  locale: DEFAULT_LANG,
  messages,
});

const gauthOption = {
  clientId: '202317172901-1cn87vgtgp10bm9hjh0bqsj70tpk6i93.apps.googleusercontent.com',
  scope: 'email',
  prompt: 'consent',
  fetch_basic_profile: true,
}

// const appleOption = {
//   clientId: 'com.example.signin',
//   scope: 'name email',
//   redirectURI: 'https://example.com',
//   state: 'STATE',
//   usePopup: true,
// }

// const image_location = "https://minpizza.smartlife.ws/storage/";
const image_location = "https://api.leferoo.com/storage/";
const app = createApp(App)
  // something vue options here ...
  .use(VueGoogleMaps, {
    load: {
      key: "AIzaSyBp7FcOz2O2oNQ2i3G419PPA32tAEFUVuw",
      libraries: "places",
    },
  })
  .use(GoogleAuth, gauthOption)
  // .use(VueAppleLogin, appleOption)
  .use(store)
  .use(router)
  .use(i18n)
  .use(Toaster)
  .use(useVuelidate)
  .provide("image_location", image_location)
  .mount("#app");
