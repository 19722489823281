<template>
  <Header />

  <section class="content">
    <div class="container">
      <div class="row">
        <div class="col-lg-12 col-md-12 col-sm-12 col-12">
          <h1>{{ $t("homeTitle") }}</h1>
          <h4>{{ $t("min_pizza") }}</h4>
        </div>
      </div>
      <div class="search-block">
        <div class="row justify-content-center">
          <div class="col-lg-6 col-md-7 col-sm-12 col-12 mm-b-3">
            <select
              class="form-select big-select"
              id="country"
              v-model="selectedCity"
              @change="setCity(selectedCity)"
            >
            <!-- <option selected>
                {{ $t('SelectCity') }}
              </option> -->
              <option
                v-for="city in cities"
                :key="city"
                :value="city.id"
              >
                {{ city.name }}
              </option>
            </select>
          </div>
          <!-- <div class="col-lg-2 col-md-5 col-sm-6 col-6 text-start">
            <button
              type="button"
              class="btn filter-btn blue-btn"
              @click="geolocate"
            >
              {{ $t("findGps") }}
            </button>
          </div> -->
          <div class="col-lg-2 col-md-5 col-sm-6 col-6 text-start">
            <router-link
              type="button"
              class="btn filter-btn blue-btn"
              to="/Search"
            >
              {{ $t("search") }}
            </router-link>
          </div>
          <div class="col-lg-8 col-md-12 col-sm-12 col-12 mt-5 home-map">
            <div id="map" class="full-location-map">
              <form class="relative-div">
                <GMapAutocomplete
                  ref="address"
                  @place_changed="getAddressData"
                  name="search"
                  class="form-control"
                  :placeholder="$t('searchPlaceholder')"
                  @keydown.enter.prevent
                >
                </GMapAutocomplete>
                <!-- <img src="images/icon-gps.png" class="gps-ico" @click="geolocate" /> -->
                <router-link to="/Search"
                  ><img src="images/icon-search.png" class="serch-ico" />
                </router-link>
              </form>
              <GMapMap
                :center="center"
                :zoom="12"
                map-type-id="terrain"
                style="width: 500px; height: 300px"
                @click="setMarker"
                ref="myMap"
              >
                <GMapCluster>
                  <GMapMarker
                    :key="index"
                    v-for="(m, index) in markers"
                    :position="m.position"
                    :clickable="true"
                    :draggable="true"
                    @click="center = m.position"
                  />
                </GMapCluster>
              </GMapMap>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent } from "vue";
import Header from "@/components/Header.vue"; // @ is an alias to /src
// @ is an alias to /src
// import VueGoogleAutocomplete from "vue-google-autocomplete";
import axios from "axios";
// import VueGoogleMaps from "@fawmi/vue-google-maps";

export default defineComponent({
  name: "Home",
  components: {
    Header,
    // VueGoogleMaps,
  },
  data() {
    return {
      address: "",
      center: {},

      markers: [
        {
          position: {
            lat: 51.093048,
            lng: 6.84212,
          },
        },
      ],
      places: [],
      gpsAddress: "",
      showMap: false,
      cities: [],
      selectedCity: null
    };
  },
  mounted() {
    // To demonstrate functionality of exposed component functions
    // Here we make focus on the user input
    // this.$refs.address.focus();
    this.geolocate();
    this.getCities(1);
  },

  methods: {
    /**
     * When the location found
     * @param {Object} addressData Data of the found location
     * @param {Object} placeResultData PlaceResult object
     * @param {String} id Input container ID
     */
    getAddressData: function (addressData) {
      this.addMarker(
        addressData.geometry.location.lat(),
        addressData.geometry.location.lng(),
        false
      );
    },
    getRestaurants() {
      this.$router.push("/Search");
    },
    geolocate: function () {
      navigator.geolocation.getCurrentPosition((position) => {
        console.log(position.coords);
        this.addMarker(position.coords.latitude, position.coords.longitude);
      });

    },
    setMarker(event) {
      console.log(event);
      this.addMarker(event.latLng.lat(), event.latLng.lng());
    },
    addMarker(lat, lng, getAddress = true) {
      this.setAccoudinates(lat, lng);
      const marker = {
        lat: lat,
        lng: lng,
      };
      this.markers[0].position = marker;
      this.center = marker;
      // eslint-disable-next-line no-undef
      let geocoder = new google.maps.Geocoder();
      geocoder.geocode({ location: marker }, (results, status) => {
        if (status === "OK" && results.length > 0) {
          this.$refs.address.$refs.input.value = getAddress
            ? results[0].formatted_address
            : this.$refs.address.$refs.input.value;
          console.log("results : ", results);
        } else {
          console.error("Geocoding request status: " + status);
          console.error(results);
        }
      });
    },

    setAccoudinates(lat, lng) {
      localStorage.setItem("lat", lat);
      localStorage.setItem("lng", lng);
    },
    getCities(city) {
      axios
        .get(`cities/${city}`, { headers: this.headers })
        .then((response) => {
          this.cities = response.data.cities;
          console.log(this.cities);
        })
        .catch((errors) => {
          console.log(errors);
        });
    },
    setCity(cityID){
      console.log(cityID);
      localStorage.setItem("city", cityID);
      this.cities.map((item, index) => {
        if(item.id == cityID){
          this.center ={
            lat: parseFloat(item.lat),
            lng: parseFloat(item.lng)
          }
          this.markers= [{ position: this.center }]
          this.setAccoudinates( parseFloat(item.lat), parseFloat(item.lng));
        }
      });
    }
  },
});
</script>
